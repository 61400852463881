import { colors } from 'styles'

export const audioVisualStyles = {
  container: {
    position: 'relative',
    width: '95%',
    backgroundColor: colors.backgroundGray,
    borderRadius: 2,
    padding: 1,

    '& .voice-visualizer': {
      display: 'flex',
      flexDirection: 'row-reverse',
      '&__buttons-container': {
        mb: 0,
      },
      '&__btn': {
        display: 'none',
      },
      '&__audio-info-container': {
        display: 'none',
      },
      '&__btn-left': {
        mr: { xs: '4px', sm: '10px' },
        backgroundColor: colors.mainPrimary,
      },
    },
  },

  timesWrap: {
    position: 'absolute',
    bottom: '7px',
    left: '81px',
    display: 'flex',
  },

  durationSlice: {
    '&::before': {
      content: '"/"',
      margin: '0 3px',
    },
  },
}
