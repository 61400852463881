import { useEffect } from 'react'
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer'
import { Box } from '@mui/material'
import { colors } from 'styles'
import mainDictionary from '@/dictionary'
import { getBlobFromUrl, useDeviceMediaQuery } from '@/utils'
import { getFormattedMinutesFromParams } from '@/utils/getDataFormat'
import { audioVisualStyles } from './styles'

type Props = {
  url?: string | null
}

export const AudioVisual = ({ url }: Props) => {
  const recorderControls = useVoiceVisualizer()
  const { setPreloadedAudioBlob, currentAudioTime, duration, error } = recorderControls
  const { isMobile } = useDeviceMediaQuery()

  const getBlob = async () => {
    if (!url) {
      return console.error(mainDictionary.somethingWentWrong)
    }

    const blobUrl = await getBlobFromUrl(url)

    if (!blobUrl) return null

    setPreloadedAudioBlob(blobUrl)
  }

  useEffect(() => {
    getBlob()
  }, [url])

  if (!url || error) {
    console.error(mainDictionary.audioNotFound)

    return null
  }

  return (
    <Box>
      <Box sx={audioVisualStyles.container}>
        <VoiceVisualizer
          mainBarColor={colors.silverLight}
          secondaryBarColor={colors.mainPrimary}
          fullscreen
          gap={0}
          height={isMobile ? '33' : '43'}
          isControlPanelShown
          isDefaultUIShown={false}
          width={isMobile ? '180' : '300'}
          isProgressIndicatorShown={false}
          isProgressIndicatorOnHoverShown={false}
          controls={recorderControls}
        />

        <Box sx={audioVisualStyles.timesWrap}>
          <Box component="span">{getFormattedMinutesFromParams(currentAudioTime)}</Box>
          {currentAudioTime > 0 && <Box component="span" sx={audioVisualStyles.durationSlice} />}
          <Box component="span">{getFormattedMinutesFromParams(duration)}</Box>
        </Box>
      </Box>
    </Box>
  )
}
