export const colors = {
  // main
  mainPrimary: '#bc8e5b',
  mainLight: '#f5d0aa',
  mainPrimaryDark: '#5e4427',
  linkColor: '#BA8D5B',
  brandNoActiveColor: 'rgba(0, 0, 0, 0.35)',
  mainLightHover: '#f5d0aa',
  mainLightHover2: '#ECEFFE',
  mainLightPressed2: '#C8D1FA',

  // text
  textPrimary: '#333',
  textSecondary: '#6f7577',
  textWhite: '#FFFFFF',
  textBlue: 'blue',
  textGreen: 'green',
  textGray: 'gray',
  textBlack: '#000',
  contrastText: '#333',
  silverLight: '#c8d3da',

  // background
  backgroundPrimary: '#FFFFFF',
  backgroundLightBlack: '#16181C',
  backgroundSecondary: '#F9F9FA',
  backgroundTertiary: '#E9EAEF',
  backgroundLine: '#DADCE5',
  backgroundGray: '#E5E5E5',
  backgroundDarkGray: '#f9fafc',
  backgroundMain: '#E5E5E5',
  backgroundGreen: '#5BBC5D',
  backgroundGreenLight: '#00800057',
  backgroundBlack: '#000',
  backgroundSilver200: '#f7f7f7',
  backGroundTableLight: '#5BBC5D',

  // status
  statusDanger: '#FF3300',
  statusLightSuccess: '#5BBC5D',
  statusLightWarning: '#E0A309',
  statusLightDanger: '#BC5BB9',
  statusLightPrimary: '#617AF1',
  statusLightSurfaceSuccess: '#EEF7ED',
  statusLightSurfaceDanger: '#FCEDEB',
  statusLightSurfacePrimary: '#F4F5FE',

  lightInProgress: '#E9FBE9',
  darkInProgress: '#134D13',
  lightWaiting: '#FBF2E9',
  lightWarning: '#FFFCF5',
  darkWaiting: '#4C3013',
  lightDenied: '#FBE9E9',
  darkDenied: '#4C1313',
  lightRouter: '#E9E9FB',
  darkRouter: '#13134D',
  lightQueue: '#F2E9FB',
  darkQueue: '#30134D',
  lightML: '#E9FBFB',
  darkML: '#134D4D',
  inputBorderColor: '#D4D3D5',
}
